import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation, useRouteError } from '@remix-run/react';
import { appConfig } from './modules/app-config.server';
import { ErrorBoundaryFallback } from './components/error-boundary';
import polarisStyles from '@shopify/polaris/build/esm/styles.css?url';
import * as gtag from '~/modules/gtags.client';
import { Fragment } from 'react/jsx-runtime';
import { useEffect } from 'react';
import newRelicFrontend from './newrelic-frontend.js?url';

export const loader = () => {
    return {
        environment: appConfig.environment,
        sentryDsn: appConfig.sentry.dsn,
        gaTrackingId: appConfig.gaTrackingId,
        newrelicFrontendJs: appConfig.environment === 'production' ? newRelicFrontend : null,
    };
};

type LeatEnv = ReturnType<typeof loader>;

declare global {
    interface Window {
        LeatEnv: LeatEnv;
    }
}

export default function App() {
    const location = useLocation();
    const loaderData = useLoaderData<typeof loader>();

    useEffect(() => {
        gtag.pageview(location.pathname, loaderData.gaTrackingId);
    }, [location, loaderData.gaTrackingId]);

    return (
        <html>
            <head>
                <title>Leat: Loyalty & Rewards</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <link rel="preconnect" href="https://cdn.shopify.com/" />
                <link rel="stylesheet" href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css" />
                <link
                    href="https://framerusercontent.com/images/7PMKyLFZFIm8K3esq5XUkBh0I.svg"
                    rel="icon"
                    media="(prefers-color-scheme: light)"
                />
                <link
                    href="https://framerusercontent.com/images/NoXdfDV2CjZRWD5kJBy6dPTprrU.svg"
                    rel="icon"
                    media="(prefers-color-scheme: dark)"
                />
                <link rel="apple-touch-icon" href="https://framerusercontent.com/images/mgXAyKIScqDjvK7JGwXCoGNIs0.png" />
                <Meta />
                <Links />
            </head>
            <body>
                <Fragment>
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${loaderData.gaTrackingId}`} />
                    <script
                        async
                        id="gtag-init"
                        dangerouslySetInnerHTML={{
                            __html: `
                              window.dataLayer = window.dataLayer || [];
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());

                              gtag('config', '${loaderData.gaTrackingId}', {
                                page_path: window.location.pathname,
                              });
                            `,
                        }}
                    />

                    {loaderData.newrelicFrontendJs ? (
                        <script type="text/javascript" src={loaderData.newrelicFrontendJs} async />
                    ) : null}
                </Fragment>

                <Outlet />
                <ScrollRestoration />
                <script id="leat-env" dangerouslySetInnerHTML={{ __html: `window.LeatEnv = ${JSON.stringify(loaderData)}` }} />
                <Scripts />
            </body>
        </html>
    );
}

export const ErrorBoundary = () => {
    const error = useRouteError();
    captureRemixErrorBoundaryError(error);

    return (
        <html>
            <head>
                <title>Leat - Oh no!</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <link rel="preconnect" href="https://cdn.shopify.com/" />
                <link rel="stylesheet" href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css" />
                <link rel="stylesheet" href={polarisStyles} />
                <Meta />
            </head>
            <body>
                <ErrorBoundaryFallback />
                <Scripts />
            </body>
        </html>
    );
};

console.info(`LEAT: ${__VERSION__}: ${__VERSION_DATE__}`);
